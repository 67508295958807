<template>
  <v-stepper v-model="steps" flat class="my-8">
    <v-stepper-header>
      <v-stepper-step
        class="stepperText"
        color="#D65646"
        step="1"
        :complete="steps > 1"
      >
        Transaksi
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        class="stepperText"
        color="#D65646"
        step="2"
        :complete="steps > 2"
      >
        Pembayaran Pinjaman
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 1,
    },
  },
};
</script>
