<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <!-- <v-text-field
      v-model="formData.pengguna"
      :rules="[(v) => !!v || 'Mohon isi pengguna']"
      label="Pengguna"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>-->

    <v-text-field
      v-model="formData.name"
      :rules="[(v) => !!v || 'Mohon isi Nama']"
      label="Nama"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.code"
      :rules="[(v) => !!v || 'Mohon isi Code']"
      label="Code"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>
    <div class="d-flex justify-lg-space-between mb-6">
      <v-card
        class="w-full md:w-1/2 pa-4 mb-6 md:mb-0 text-center"
        elevation="0"
        outlined
      >
        <h4>AVAILABLE USER</h4>
        <!-- <v-btn
          text
          v-for="admins in this.formData.unselect"
          :key="admins.id"
          class="px-4 py-3 bg-blue-600 rounded-md text-white outline-none hover:bg-blue-700 hover:shadow-lg focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform flex flex-row justify-center"
          color="primary"
          @click="clickAdmin(admins)"
        >{{ admins.name }}</v-btn>-->
        <v-btn
          v-for="admins in formData.unselect"
          :key="admins.id"
          width="250"
          color="primary"
          @click="clickAdmin(admins)"
          class="ma-2 bg-blue-600 rounded-md text-white outline-none hover:bg-blue-700 hover:shadow-lg focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform flex flex-row justify-center"
        >
          <span>{{ admins.name }}</span>
          <v-icon right dark>
            mdi-chevron-double-right
          </v-icon>
        </v-btn>
        <!-- <control v-model="formData.email" name="email" required autocomplete="email" /> -->
      </v-card>
      <div class="px-2" />
      <v-card
        class="w-full md:w-1/2 pa-4 mb-6 md:mb-0 text-center"
        elevation="0"
        outlined
      >
        <h4>SELECTED USER</h4>
        <!-- <v-btn
          text
          v-for="admins in formData.select"
          :key="admins.id"
          @click="clickAdminSelect(admins)"
          class="px-4 py-3 bg-blue-600 rounded-md text-white outline-none hover:bg-blue-700 hover:shadow-lg focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform flex flex-row justify-center"
          color="primary"
        >{{ admins.name }}</v-btn>-->
        <v-btn
          v-for="admins in formData.select"
          :key="admins.id"
          width="250"
          color="primary"
          @click="clickAdminSelect(admins)"
          class="ma-2 bg-blue-600 rounded-md text-white outline-none hover:bg-blue-700 hover:shadow-lg focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform flex flex-row justify-center"
        >
          <v-icon left dark>
            mdi-chevron-double-left
          </v-icon>
          <span>{{ admins.name }}</span>
        </v-btn>
        <!-- <control v-model="formData.email" name="email" required autocomplete="email" /> -->
      </v-card>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Description</th>
            <th>Permission</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="admins in formData.permission" :key="admins.id">
            <td data-label="Feature">{{ admins.code }}</td>
            <td data-label="Description">{{ admins.name }}</td>
            <td class="actions-cell h-0">
              <v-checkbox v-model="admins.check"></v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-form>
</template>

<script>
import store from "../../store/index";
export default {
  props: {
    formAdmin: Object,
  },
  async created() {
    let dataUnsel = await store.dispatch("admin/getAdminUnSel");
    console.log(dataUnsel, "keluar");
    this.formData.unselect = dataUnsel.unselect;
    let data = await store.dispatch("admin/getAdminPermissions");
    // console.log(data.length)

    for (const iterator of data) {
      // console.log(iterator, "hello")
      iterator.check = false;
      this.formData.permission.push(iterator);
    }
    console.log(this.formData.permission, "hallo");
    // this.permission = store.state.admin.adminPermissions;
  },
  methods: {
    clickAdminSelect(payload) {
      let findThe = this.formData.select.findIndex((el) => el.id == payload.id);
      this.formData.select.splice(findThe, 1);
      this.formData.unselect.push(payload);
    },
    clickAdmin(payload) {
      // adminUnselectedPayload = {};
      //console.log(payload, "test");
      let findThe = this.formData.unselect.findIndex(
        (el) => el.id == payload.id
      );
      this.formData.unselect.splice(findThe, 1);
      this.formData.select.push(payload);
      //console.log(findThe);
    },
  },
  data: () => ({
    valid: true,
    formData: {
      name: "",
      code: "",
      unselect: [],
      permission: [],
      select: [],
    },
    statusKepemilikanRumahOptions: [
      {
        name: "Memiliki Rumah Sendiri",
        value: 1,
      },
      {
        name: "Tidak Memiliki Rumah Sendiri",
        value: 2,
      },
      {
        name: "Badan Hukum",
        value: 3,
      },
      {
        name: "Lainnya",
        value: 4,
      },
    ],
    reportOptions: [
      {
        label: "Ya",
        id: 1,
      },
      {
        label: "Tidak",
        id: 0,
      },
    ],
  }),

  mounted() {
    this.formData = this.formAdmin;
  },
};
</script>
